import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


export const Container = styled.div`
 
  box-sizing: border-box;
  font-family: 'Segoe UI', sans-serif;
  min-height:fit-content; 
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
 

  margin: 0 auto;

  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: row;
    padding: 10px;
    flex-wrap: nowrap; /* Verhindert das Stapeln */
  }
`;

export const List = styled.div`
  flex: 0 0 20%; /* Fixiert die Breite auf 25% */
  
  padding: 15px;
  border-radius: 8px;


  @media (max-width: 768px) {
    flex: 0 0 15%; /* Beibehaltung der Breite auf kleineren Bildschirmen */
    margin-right: 10px;
    padding: 15px;
  }
`;

export const CalendarStyleWrapper = styled.div`
  flex: 1; /* Nimmt den restlichen Platz ein */
 min-height:fit-content;
  margin-top: 0;
  background: #fff;

  border-radius: 8px;


  @media (max-width: 768px) {
    flex: 1;
    height: 100%;
    margin-top: 0;
  }

  .rbc-calendar {
    height: 100%; /* Volle Höhe */
  }
`;
export const StyledDatePicker = styled(DatePicker)`
  .react-datepicker {
    width:100%;
    font-family: 'Segoe UI', sans-serif;
    border-radius: 8px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .react-datepicker__header {
    background-color: #943534;
    border-bottom: none;
    padding-top: 10px;
    border-radius: 8px 8px 0 0;
  }

  .react-datepicker__current-month {
    font-size: 1.2em;
    color: white;
  }

  .react-datepicker__day-name, .react-datepicker__day {
    color: #333;
    font-weight: 500;
  }

  .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
    background-color: #943534;
    color: white;
    border-radius: 50%;
  }

  .react-datepicker__day--today {
    font-weight: bold;
    color: #943534;
  }

  .react-datepicker__day--outside-month {
    color: #ccc;
  }

  .react-datepicker__triangle {
    display: none;
  }

  @media (max-width: 768px) {
    .react-datepicker {
      width: 100%;
    }

    .react-datepicker__header {
      padding-top: 5px;
    }

    .react-datepicker__current-month {
      font-size: 1em;
    }
  }
`;