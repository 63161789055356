import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {  EmployeesContext, EventsContext } from '../context/EveMitContext';
import CalendarWithDragAndDrop from '../CalendarKomponent/CaledarKomponent';
import MitarbeiterList from '../MitarbeiterList/MitarbeiterList';
import { Container, CalendarStyleWrapper, List, Content,StyledDatePicker} from './Home.element';
import Header from '../Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTimes } from '@fortawesome/free-solid-svg-icons';

function Home() {
  const [selectedDate, setSelectedDate] = useState(() => {
    const savedDate = localStorage.getItem('selectedDate');
    return savedDate ? new Date(savedDate) : new Date();
  });
  const [isListVisible, setIsListVisible] = useState(false);

  const handleSelectDate = (date) => {
    setSelectedDate(date);
  };

  const toggleListVisibility = () => {
    setIsListVisible(!isListVisible);
  };

  useEffect(() => {
    if (selectedDate) {
      localStorage.setItem('selectedDate', selectedDate.toISOString());
    }
  }, [selectedDate]);

  return (

        <Container>
          <Header />
          <Content>
            <div>
              <FontAwesomeIcon 
                icon={isListVisible ? faTimes : faUser} 
                onClick={toggleListVisibility} 
                style={{ cursor: 'pointer', fontSize: '24px', color: '#2980b9' }} 
              />
              {isListVisible && (
                <List>
                  <StyledDatePicker
                    selected={selectedDate}
                    onChange={handleSelectDate}
                    inline
                    inputMode="numeric"  
                    pattern="\d*"        
                  />
                  <EmployeesContext.Consumer>
                    {({ employees }) => (
                      <EventsContext.Consumer>
                        {({ setFilteredEmployees }) => (
                          <MitarbeiterList
                            employees={employees}
                            setFilteredEmployees={setFilteredEmployees}
                          />
                        )}
                      </EventsContext.Consumer>
                    )}
                  </EmployeesContext.Consumer>
                </List>
              )}
            </div>
            <CalendarStyleWrapper isListVisible={isListVisible.toString()}>
              <EventsContext.Consumer>
                {({ allEvents, setAllEvents }) => (
                  <CalendarWithDragAndDrop
                    allEvents={allEvents}
                    setAllEvents={setAllEvents}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                  />
                )}
              </EventsContext.Consumer>
            </CalendarStyleWrapper>
          </Content>
        </Container>
      
    
  );
}

export default Home;
