import React, { createContext, useState, useEffect } from 'react';
import API from './axioSetup';  
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);  // Start in loading state
  const navigate = useNavigate();  

  // Function to check and renew the token if necessary
  const checkAndRenewToken = async () => {
    const token = sessionStorage.getItem('access_token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        
        // If token is about to expire (less than 60 seconds left), renew the token
        if (decodedToken.exp - currentTime < 60) {
          console.log('Token is about to expire, attempting to renew...');
          const { data } = await API.post('/refresh-token');
          sessionStorage.setItem('access_token', data.accessToken);  // Store the new token in sessionStorage
          const newDecodedToken = jwtDecode(data.accessToken);
          console.log('checkAndRenewToken', newDecodedToken);
          
          setUser(newDecodedToken);
          setRole(newDecodedToken.role); 
        } else {
          setUser(decodedToken);
          setRole(decodedToken.role);  // Set role
        }
      } catch (error) {
        console.error('Error renewing token:', error);
        logout();  // Log the user out if token renewal fails
      }
    } else {
      logout();  // If no token exists, log the user out
    }
    setLoading(false);  // Set loading to false after token check
  };

  // useEffect to check for a token on app load
  useEffect(() => {
    checkAndRenewToken();
  }, []);

  // Login function
  const login = async (username, password) => {
    try {
      setLoading(true);  // Start loading when login is initiated
      const { data } = await API.post('/login', { username, password });
      sessionStorage.setItem('access_token', data.accessToken);  // Store the new access token in sessionStorage
      const decodedToken = jwtDecode(data.accessToken);
      setUser(decodedToken);
      setRole(decodedToken.role);  // Set role from decoded token

      // Navigate to admin page or login based on role
      if (decodedToken.role === 'filiale') {
        navigate(`/user/${decodedToken.username}`);  // Admin dashboard
      } else {
        navigate('/login');  // Non-admins should not access admin pages
      }
    } catch (error) {
      console.error('Login failed:', error);
      throw new Error('Invalid login credentials');
    } finally {
      setLoading(false);  // Stop loading after login attempt
    }
  };

  // Logout function
  const logout = () => {
    console.log('Logging out, removing token');
    sessionStorage.removeItem('access_token');  // Remove token from sessionStorage
    setUser(null);
    setRole(null);  // Reset user and role
    navigate('/login');  // Redirect to login
    setLoading(false);  // Ensure loading is false after logout
  };

  return (
    <AuthContext.Provider value={{ user, role, login, logout, loading, checkAndRenewToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
