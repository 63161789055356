import styled from "styled-components";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const FormContainer = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 50%;
  max-width: 700px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
  max-height: 90vh; /* Maximale Höhe auf 90% des Viewports beschränkt */
  
  @media (max-width: 768px) {
    width: 80%;
    padding: 15px;
    max-height: 85vh; /* Kleinere Bildschirme erhalten etwas weniger Höhe */
  }

  @media (max-width: 480px) {
    width: 95%;
    padding: 10px;
    max-height: 80vh;
  }
`;

// Andere Styled-Components bleiben gleich...

export const Header = styled.div`
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FormHeader1 = styled.h3`
  font-size: 2rem;
  margin: 0;
  color: #333;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

export const InputEv = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 480px) {
    font-size: 0.9rem;
    padding: 10px;
  }
`;

export const SelectEmployee = styled.select`
  width: 100%;
  padding: 12px;
  height: 100px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  font-size: 1rem;
  color: #333;

  @media (max-width: 480px) {
    height: 150px;
  }
`;

export const MitarbeiterPicker = styled.div`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  background-color: #f5f5f5;
`;

export const DataContainer = styled.div`
  width: 100%;
  padding: 10px;
  margin: 10px 10px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const DataContainerDatePicker = styled.div`
  width: 100%;
  display: ${(props) => (props.showRepeatComponent ? 'none' : 'flex')};
  padding: 10px ;
  margin: 5px 5px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 15px;
  }

  // Flex-grow hinzugefügt
  div {
    flex-grow: 1;
    &:first-child {
      margin-right: 10px;
    }
  }
`;

export const Button = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  font-size: 1rem;
  transition: background-color 0.3s;
  display: ${(props) => (props.showRepeatComponent ? 'none' : 'flex')};
  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 480px) {
    padding: 10px;
    font-size: 0.9rem;
  }
`;
export const StyledDatePicker = styled(DatePicker)`
  .react-datepicker {
    font-family: 'Segoe UI', sans-serif;
    border-radius: 8px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .react-datepicker__header {
    background-color: #943534;
    border-bottom: none;
    padding-top: 10px;
    border-radius: 8px 8px 0 0;
  }

  .react-datepicker__current-month {
    font-size: 1.2em;
    color: white;
  }

  .react-datepicker__day-name, .react-datepicker__day {
    color: #333;
    font-weight: 500;
  }

  .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
    background-color: #943534;
    color: white;
    border-radius: 50%;
  }

  .react-datepicker__day--today {
    font-weight: bold;
    color: #943534;
  }

  .react-datepicker__day--outside-month {
    color: #ccc;
  }

  .react-datepicker__triangle {
    display: none;
  }

  @media (max-width: 768px) {
    .react-datepicker {
      width: 100%;
    }

    .react-datepicker__header {
      padding-top: 5px;
    }

    .react-datepicker__current-month {
      font-size: 1em;
    }
  }
`;