import styled, { keyframes } from 'styled-components';

const colorAnimation = keyframes`
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
`;

export const Container = styled.div`
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0 0;
  background: linear-gradient(90deg, rgba(148, 5, 52, 0.61) 18%, rgba(118, 110, 93, 1) 48%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  animation: ${colorAnimation} 10s linear infinite;
  
`;

export const Background = styled.div`
  width: 100%;
  max-width: 430px;
  height: 520px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  @media (max-width: 768px) {
    width: 50%;
    height: auto;
    position: relative;
    transform: none;
    left: 0;
    top: 0;
  }
`;

const Shape = styled.div`
  height: 200px;
  width: 200px;
  position: absolute;
  border-radius: 50%;
  @media (max-width: 768px) {

    height: auto;
    position: absolute;
    transform: none;
    top:80px;
   left:30px;
  }
`;

export const ShapeFirst = styled(Shape)`
  background: linear-gradient(#1845ad, #23a2f6);
  left: -80px;
  top: -80px;
  @media (max-width: 768px) {
    left: -10px;
  top: -200px;
    height: 150px;
    width: 150px;
  }
`;

export const ShapeLast = styled(Shape)`
  background: linear-gradient(to right, #ff512f, #f09819);
  right: -30px;
  bottom: -200px;
  @media (max-width: 768px) {
 
    height: 150px;
    width: 150px;
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 400px;
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 15px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.6);
  padding: 40px 30px;
  @media (max-width: 768px) {
    width: 90%;
    height: auto;
    position: relative;
    transform: none;
    left: -50px;
    top: 0;
  }
`;

export const FormHeader = styled.h2`
  font-size: 32px;
  font-weight: 900;
  line-height: 42px;
  text-align: left;
  color: #ffffff;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const Label = styled.label`
  display: block;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 600;
  color: whitesmoke;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const Input = styled.input`
  display: block;
  height: 50px;
  width: 80%;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 5px;
  padding: 0 15px;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 300;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.8);
  }
  @media (max-width: 768px) {
    height: 40px;
    font-size: 14px;
  }
`;

export const Button = styled.button`
  margin-top: 40px;
  width: 85%;
  background-color: #1845ad;
  color: #ffffff;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
  transition: background-color 0.3s ease, transform 0.3s ease;
  &:hover {
    background-color: #23a2f6;
    transform: translateY(-3px);
  }
  @media (max-width: 768px) {
    padding: 10px 0;
    font-size: 16px;
  }
`;

export const ErrorMessage = styled.div`
  color: #ff512f;
  margin-top: 15px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;
