import React, { useState, useEffect, useContext } from 'react';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from 'date-fns/locale/de';
import RepeatKomponent from './RepeatKomponent';
import { FormContainer, FormHeader1, InputEv, Button, SelectEmployee, Header, DataContainer, MitarbeiterPicker, DataContainerDatePicker ,StyledDatePicker} from './EventForm.element';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import API from '../context/axioSetup';
import { EmployeesContext, EventsContext } from '../context/EveMitContext';
import useEmployeeSelection from '../Hooks/useEmployeeSelection';
import format from 'date-fns/format';

registerLocale('de', de);

function EventForm({ newEvent, setNewEvent, setShowEventForm }) {
  const { employees } = useContext(EmployeesContext);
  const { fetchEventsAndUpdate } = useContext(EventsContext);
  const { selectedEmployees, addSelectedEmployees, removeSelectedEmployee, generateEventColor } = useEmployeeSelection([], employees);
  const [showRepeatComponent, setShowRepeatComponent] = useState(false);
  const [isGanzerTag, setIsGanzerTag] = useState(false);

  useEffect(() => {
    if (isGanzerTag) {
      const startOfDay = new Date(newEvent.startTimeEvent || new Date());
      startOfDay.setHours(0, 0, 0, 0);
      const nextDay = new Date(startOfDay);
      nextDay.setDate(nextDay.getDate() + 1);
      nextDay.setHours(0, 0, 0, 0);

      setNewEvent({ ...newEvent, startTimeEvent: startOfDay, endTimeEvent: nextDay });
    }
  }, [isGanzerTag]);

  const handleAddEvent = async () => {
    if (!newEvent.title.trim()) {
      window.alert('Bitte füllen Sie Titel und Beschreibung aus.');
      return;
    }

    if (!newEvent.startTimeEvent || !newEvent.endTimeEvent) {
      window.alert('Bitte wählen Sie Start- und Endzeit aus.');
      return;
    }

    if (selectedEmployees.length === 0) {
      window.alert('Bitte wählen Sie mindestens einen Mitarbeiter aus.');
      return;
    }

    if (showRepeatComponent) {
      if (!newEvent.repeatOption || newEvent.repeatOption === 'never') {
        window.alert('Bitte wählen Sie eine Wiederholungsoption aus.');
        return;
      }

      if ((newEvent.repeatOption === 'weekly' || newEvent.repeatOption === 'monthly' || newEvent.repeatOption === 'yearly') && (!newEvent.repeatEnd || newEvent.repeatEnd === null)) {
        window.alert('Bitte wählen Sie ein Enddatum für die Wiederholung aus.');
        return;
      }
    }

    const formattedStartDate = format(newEvent.startTimeEvent, 'yyyy-MM-dd HH:mm:ss', { locale: de });
    const formattedEndDate = format(newEvent.endTimeEvent, 'yyyy-MM-dd HH:mm:ss', { locale: de });

    const newEventObj = {
      title: newEvent.title,
      start: formattedStartDate,
      end: formattedEndDate,
      users: selectedEmployees,
      color: generateEventColor(selectedEmployees),
      description: newEvent.description,
    };

    try {
      const response = await API.post('/addEvent', newEventObj);

      if (response.status === 201) {
        window.alert('Event erfolgreich hinzugefügt.');
        setNewEvent({
          title: '',
          startTimeEvent: null,
          endTimeEvent: null,
          description: '',
          selectedEmployees: [],
        });

        setShowRepeatComponent(false);
        setShowEventForm(false);
        fetchEventsAndUpdate();
      } else {
        console.error('Fehler beim Hinzufügen des Events');
      }
    } catch (error) {
      console.error('Fehler beim Bearbeiten des Events:', error);
      window.alert('Ein Fehler ist aufgetreten: ' + error.message);
    }
  };

  const handleRepeatCheckbox = (e) => {
    const isDataComplete = newEvent.title.trim() && selectedEmployees.length > 0;
    if (e.target.checked && !isDataComplete) {
      window.alert('Bitte vervollständigen Sie die Eingaben für Titel, Beschreibung und Mitarbeiter.');
    } else {
      setShowRepeatComponent(e.target.checked);
    }
  };

  const handleGanzerTagCheckbox = (e) => {
    setIsGanzerTag(e.target.checked);
  };



  return (
    <FormContainer id='form-container' showrepeatComponent={showRepeatComponent}>
      <FontAwesomeIcon icon={faTimes} onClick={() => setShowEventForm(false)} style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }} />
      <Header>
        <FormHeader1>Neuer Termin</FormHeader1>
      </Header>
      <InputEv type="text" placeholder="Titel" value={newEvent.title} onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })} />
      <InputEv type="text" placeholder="Beschreibung" value={newEvent.description} onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })} />

      <SelectEmployee multiple onChange={(e) => addSelectedEmployees(e.target.selectedOptions, employees)}>
        {employees.map((employee) => (
          <option key={employee.mitarbeiter_id} value={employee.name} style={{ backgroundColor: `${employee.farbe}`, color: 'white' }}>
            {employee.name}
          </option>
        ))}
      </SelectEmployee>
      <MitarbeiterPicker>
        {selectedEmployees.map((employee) => (
          <span key={employee.id} style={{ display: 'flex', alignItems: 'center', backgroundColor: employee.color }}>
            {employee.name}
            <FontAwesomeIcon icon={faTimes} onClick={() => removeSelectedEmployee(employee.name)} />
          </span>
        ))}
      </MitarbeiterPicker>

      <DataContainerDatePicker showrepeatComponent={showRepeatComponent}>
        <div>
          Von:
          <StyledDatePicker
            selected={new Date(newEvent.startTimeEvent || new Date())}
            onChange={(date) => {
              if (!isGanzerTag) {
                setNewEvent({ ...newEvent, startTimeEvent: date });
              }
            }}
            showTimeSelect={!isGanzerTag}
            timeFormat="HH:mm"
            timeIntervals={30}
            timeCaption="Zeit"
            dateFormat="Pp"
            locale="de"
            inputMode="numeric"
            pattern="\d*"
          />
        </div>
        <div>
          Bis:
          <StyledDatePicker
            selected={new Date(newEvent.endTimeEvent || new Date())}
            onChange={(date) => {
              if (!isGanzerTag) {
                setNewEvent({ ...newEvent, endTimeEvent: date });
              }
            }}
            showTimeSelect={!isGanzerTag}
            timeFormat="HH:mm"
            timeIntervals={30}
            timeCaption="Zeit"
            dateFormat="Pp"
            locale="de"
            pattern="\d*"
          />
        </div>
      </DataContainerDatePicker>

      <DataContainer>
        <div>
          <input type="checkbox" id="ganzerTag" name="ganzerTag" onChange={handleGanzerTagCheckbox} />
          <label htmlFor="ganzerTag">Ganzer Tag</label>
        </div>
        <div>
          <input type="checkbox" id="wiederholen" name="wiederholen" onChange={handleRepeatCheckbox} />
          <label htmlFor="wiederholen">Wiederholen</label>
        </div>
      </DataContainer>

      {showRepeatComponent && (
        <RepeatKomponent
          newEvent={newEvent}
          users={selectedEmployees}
          color={generateEventColor(selectedEmployees)}
          setShowEventForm={setShowEventForm}
          fetchEventsAndUpdate={fetchEventsAndUpdate}
          setNewEvent={ setNewEvent }      />
      )}

      <Button onClick={handleAddEvent} showRepeatComponent={showRepeatComponent}>Hinzufügen</Button>
    </FormContainer>
  );
}

export default EventForm;
