import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/authContext';
import LoginComponent from './Login/LoginComponent';
import Home from './Home/Home';
import SearchEvent from './SearchComponent/SearchEvent';

import { LanguageProvider } from './context/LanguageContext';
import PrivateRoute from './PrivateRoute';
import { EmployeesProvider,EventsProvider} from './context/EveMitContext'; 









 

function App() {
  return (
    <Router>
    <AuthProvider>
      <LanguageProvider>
        <EmployeesProvider>
        <EventsProvider>
        <Routes>
     
      <Route path="/login" element={<LoginComponent />} />
      <Route 
        path="/user/:userId" 
        element={<PrivateRoute><Home /></PrivateRoute>} 
      />
      <Route 
        path="/user/:userId/search" 
        element={<PrivateRoute><SearchEvent /></PrivateRoute>} 
      />
     {/** <Route path="*" element={<ErrorPage />} /> */}
    </Routes>
        </EventsProvider>

      </EmployeesProvider>
       
       
      </LanguageProvider>
      </AuthProvider>
      </Router>
  );
}

export default App;

