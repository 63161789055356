import React, { useState } from 'react';
import {
    CheckboxLabel,
    CheckboxInput,
    EventContent,
    EventInfo,
    EventTitle,
    EmployeeBadge,
    EmployeeContainer,
    EventContainer,
    Divider
} from './EventDetail.element';
import EditSearch from '../EditSearch/EditSearch';

function EventDetails({ filteredEvents }) {
    const [selectedEvents, setSelectedEvents] = useState([]);
    const [showEdit, setShowEdit] = useState(false);

    const handleSelectAllChange = (e) => {
        if (e.target.checked) {
            setSelectedEvents(filteredEvents);
        } else {
            setSelectedEvents([]);
        }
    };

    const handleCheckboxChange = (event) => {
        if (selectedEvents.includes(event)) {
            setSelectedEvents(selectedEvents.filter(e => e !== event));
        } else {
            setSelectedEvents([...selectedEvents, event]);
        }
    };

    const handleEditButtonClick = () => {
        setShowEdit(true);
    };

    return (
        <>
            <CheckboxLabel>
                <CheckboxInput
                    type="checkbox"
                    checked={selectedEvents.length === filteredEvents.length}
                    onChange={handleSelectAllChange}
                />
                Select Alles
            </CheckboxLabel>
            <button onClick={handleEditButtonClick}>Bearbeiten</button>
            {filteredEvents.map(event => (
                <EventContainer key={event.event_id}>
                    <CheckboxInput
                        type="checkbox"
                        checked={selectedEvents.includes(event)}
                        onChange={() => handleCheckboxChange(event)}
                    />
                    <EventContent>
                        <EventTitle>{event.title}</EventTitle>
                        <EventInfo>Start: {new Date(event.start_datetime).toLocaleString()} - Ende: {new Date(event.end_datetime).toLocaleString()}</EventInfo>
                        <EventInfo>Mitarbeiter:</EventInfo>
                        <EmployeeContainer>
                            {event.employee_names.map(employee => (
                                <EmployeeBadge key={employee.id} color={employee.color}>
                                    {employee.name}
                                </EmployeeBadge>
                            ))}
                        </EmployeeContainer>
                        <EventInfo>Description: {event.description}</EventInfo>
                        <Divider />
                    </EventContent>
                </EventContainer>
            ))}
            {showEdit && selectedEvents.length > 0 && (
                <EditSearch hoveredEvents={selectedEvents} setShowEventEdit={setShowEdit} />
            )}
        </>
    );
}

export default EventDetails;
