import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Container, EmployeeList } from './MitarbeiterList.element';

const MitarbeiterList = ({ employees, setFilteredEmployees }) => {
  const [employeeStates, setEmployeeStates] = useState({});
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);
  const [allVisible, setAllVisible] = useState(false); // Zustand für alle Mitarbeiter (false bedeutet "Events anzeigen", Auge offen)

  // Initialisiere die Mitarbeiter so, dass die Events angezeigt werden (alle Augen offen)
  useEffect(() => {
    const initialVisibleEmployees = []; // Keine IDs bedeuten: alle Mitarbeiter-Events werden angezeigt
    setSelectedEmployeeIds(initialVisibleEmployees);
    setFilteredEmployees(initialVisibleEmployees);
  }, [employees, setFilteredEmployees]);

  const toggleEyeFilter = (employeeID) => {
    const isIgnored = selectedEmployeeIds.includes(employeeID);

    setEmployeeStates((prevStates) => {
      const updatedStates = { ...prevStates };
      updatedStates[employeeID] = !isIgnored;
      return updatedStates;
    });

    if (isIgnored) {
      // Wenn der Mitarbeiter ignoriert wurde (Auge zu), jetzt anzeigen (Auge offen)
      const newSelectedIds = selectedEmployeeIds.filter((id) => id !== employeeID);
      setSelectedEmployeeIds(newSelectedIds);
      setFilteredEmployees(newSelectedIds); // Die Events dieses Mitarbeiters anzeigen
    } else {
      // Wenn Mitarbeiter angezeigt wurde (Auge offen), jetzt ignorieren (Auge zu)
      const newSelectedIds = [...selectedEmployeeIds, employeeID];
      setSelectedEmployeeIds(newSelectedIds);
      setFilteredEmployees(newSelectedIds); // Die Events dieses Mitarbeiters ignorieren
    }
  };

  const toggleAllEmployeesVisibility = () => {
    if (allVisible) {
      // Wenn alle Mitarbeiter-Events ignoriert werden (Auge zu), jetzt anzeigen (Auge offen)
      setSelectedEmployeeIds([]);
      setFilteredEmployees([]);
    } else {
      // Wenn alle Mitarbeiter-Events angezeigt werden (Auge offen), jetzt ignorieren (Auge zu)
      const allEmployeeIds = employees.map((e) => e.mitarbeiter_id);
      setSelectedEmployeeIds(allEmployeeIds);
      setFilteredEmployees(allEmployeeIds);
    }
    setAllVisible(!allVisible); // Zustand umkehren
  };

  return (
    <Container>
      <h3>
        Mitarbeiter Liste
        <FontAwesomeIcon
          icon={allVisible ? faEyeSlash : faEye}
          onClick={toggleAllEmployeesVisibility}
          style={{ marginLeft: '10px', cursor: 'pointer' }}
        />
      </h3>
      <EmployeeList>
        {employees.map((employee) => (
          <li
            key={employee.mitarbeiter_id}
            style={{
              backgroundColor: employee.farbe,
              color: selectedEmployeeIds.includes(employee.mitarbeiter_id) ? '#495057' : '#dc3545', // Rot, wenn Events ignoriert werden (Auge zu)
            }}
          >
            <span>{employee.name}</span>
            <FontAwesomeIcon
              icon={selectedEmployeeIds.includes(employee.mitarbeiter_id) ? faEyeSlash : faEye}
              onClick={() => toggleEyeFilter(employee.mitarbeiter_id)}
              style={{ cursor: 'pointer' }}
            />
          </li>
        ))}
      </EmployeeList>
    </Container>
  );
};

export default MitarbeiterList;
