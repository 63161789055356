import styled from 'styled-components';

export const Container = styled.div`




  border-radius: 10px;
  font-family: 'Segoe UI', sans-serif;

  h3 {
    margin-bottom: 20px;
    font-size: 1.6em;
    color: #343a40;
    text-align: center;
  }

  @media (max-width: 768px) {
    padding: 15px;
    h3 {
      font-size: 1.4em;
    }
  }
`;

export const EmployeeList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 600px; /* Grenze für größere Bildschirme */
  display: flex;
  flex-direction: column;
  gap: 12px; /* Abstände zwischen den Listenelementen */

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px;
    border-radius: 8px;
    background-color: #f8f9fa;
    transition: background-color 0.3s ease, color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

    &:hover {
      background-color: #e9ecef;
    }

    span {
      font-size: 1em;
      font-weight: 500;
      color: #495057;
    }

    svg {
      cursor: pointer;
      font-size: 1.3em;
      color: #495057;
      transition: color 0.3s ease;
    }

    &:hover svg {
      color: #943534;
    }
  }

  @media (max-width: 768px) {
    li {
      flex-direction: column;
      align-items: flex-start;
      padding: 10px;

      span {
        margin-bottom: 10px;
        font-size: 1.1em;
      }
    }
  }
`;
