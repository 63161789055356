import axios from 'axios';
import Cookies from 'js-cookie';

const API = axios.create({
  baseURL: 'https://api.kalender.gronde.eu/api',
});

// Request-Interceptor
API.interceptors.request.use((config) => {
  const token = Cookies.get('token');
  console.log('Token im Request-Interceptor:', token);  // Debugging
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  console.error('Fehler im Request-Interceptor:', error);  // Debugging
  return Promise.reject(error);
});

// Response-Interceptor
API.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  const originalRequest = error.config;

  if (error.response && error.response.status === 401 && !originalRequest._retry) {
    console.log('401 Unauthorized, versuche Refresh-Token', error.response);  // Debugging
    originalRequest._retry = true;

    try {
      const { data } = await axios.post('https://api.kalender.gronde.eu/api/refresh-token');
      console.log('Neuer Access-Token erhalten:', data.accessToken);  // Debugging
      Cookies.set('token', data.accessToken);
      originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
      return API(originalRequest);  // Originale Anfrage mit neuem Token wiederholen
    } catch (err) {
      console.error('Fehler beim Abrufen des neuen Access-Tokens:', err);  // Debugging
      window.location.href = '/login';
    }
  }

  return Promise.reject(error);
});

export default API;

