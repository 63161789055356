import styled from 'styled-components';


// Styled Component für den Container der Tabs
export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%; // Fix für den Tippfehler
  margin: 0 auto;
  font-family: 'Segoe UI';
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Styled Component für die Tab-Buttons
export const TabButton = styled.button`
  background-color: ${(props) => (props.isActive ? 'red' : '#007bff')};
  color: #fff;
  padding: 10px 20px;
  border: 1px solid #ccc; // Border auslagern
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
  font-size: 14px;
  width: 100px;
  transition: background-color 0.3s ease; // Hinzufügen von Hover-Effekten

  &:hover {
    background-color: ${(props) => (props.isActive ? 'red' : '#0056b3')};
  }
`;

// Styled Component für den Tab-Inhalt
export const TabContent = styled.div`
  display: ${(props) => (props.isActive ? 'block' : 'none')};
  padding: 10px;
  border: 1px solid #ccc; // Border auslagern
  border-radius: 5px;
  margin: 10px 0;
  width: 100%;
  background: ${(props) => (props.isActive ? 'lightblue' : 'white')};
`;

// Styled Component für den Tab-Titel
export const TabTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

// Styled Component für den Tab-Text
export const TabText = styled.p`
  font-size: 1rem;
`;

// Styled Component für den Tab-Überschrift
export const TabHeader = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #007bff;
`;

// Styled Component für den aktiven Tab-Button
export const ActiveTabButton = styled(TabButton)`
  background-color: #fff;
  color: #007bff;
`;

// Styled Component für das select-Element
export const StyledSelect = styled.select`
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 5px;
`;
