import React, { useContext, useState, useEffect } from 'react';
import { EventsContext } from '../context/EveMitContext';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Container, DatePickerContainer, Title } from './SearchEvent.element';
import EventDetails from './EventDetail/EventDetails';

function SearchEvent() {
    const { allEvents } = useContext(EventsContext);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    // State for dynamically filtered events
    const [filteredEvents, setFilteredEvents] = useState([]);
    // State for dynamically filtered options
    const [eventOptions, setEventOptions] = useState([]);

    // Handle select change
    const handleSelectChange = (selected) => {
        setSelectedOptions(selected || []);
    };

    // Update filtered events and event options based on date range and selected options
    useEffect(() => {
        // Filter events based on the date range
        let filtered = allEvents;

        if (startDate || endDate) {
            filtered = filtered.filter(event => {
                const eventDate = new Date(event.start_datetime);
                if (startDate && endDate) {
                    return eventDate >= startDate && eventDate <= endDate;
                } else if (startDate) {
                    return eventDate >= startDate;
                } else if (endDate) {
                    return eventDate <= endDate;
                }
                return true;
            });
        }

        // Update event options for the select dropdown based on the filtered events
        const options = filtered.map(event => ({
            value: event.event_id,
            label: `${event.title} - ${new Date(event.start_datetime).toLocaleString()} - ${event.employee_name}`,
            event
        }));

        setEventOptions(options);

        // Further filter the events if specific options are selected
        if (selectedOptions.length > 0) {
            const selectedTitles = selectedOptions.map(option => option.event.title);
            filtered = filtered.filter(event => selectedTitles.includes(event.title));
        }

        setFilteredEvents(filtered);
    }, [allEvents, selectedOptions, startDate, endDate]);

    return (
        <Container>
            <DatePickerContainer>
                <DatePicker
                    className="date-picker"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    placeholderText="Startdatum auswählen"
                    dateFormat="dd/MM/yyyy"
                />
                <DatePicker
                    className="date-picker"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    placeholderText="Enddatum auswählen"
                    dateFormat="dd/MM/yyyy"
                />
            </DatePickerContainer>
            <Select
                options={eventOptions}
                onChange={handleSelectChange}
                isMulti
                placeholder="Suche nach Events"
            />
            {filteredEvents.length > 0 && (
                <>
                    <Title>Ausgewählte Event Details:</Title>
                    <EventDetails filteredEvents={filteredEvents} />
                </>
            )}
        </Container>
    );
}

export default SearchEvent;
