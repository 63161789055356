import React, { useState, useContext } from 'react';
import {
  TabButton,
  TabContent,
  TabTitle,
  TabText,
  TabsContainer,
  ButtonContainer,
} from './Repeat.element';

import DatePicker from 'react-datepicker';
import API from '../context/axioSetup';
const RepeatKomponent = ({ newEvent, users, color, setShowEventForm ,fetchEventsAndUpdate}) => {
console.log(fetchEventsAndUpdate);


  const options = ['Täglich', 'Mo-Fr', 'Wöchentlich', 'Monatlich', 'Jährlich'];
  const [repeatData, setRepeatData] = useState({
    option: options[0],
    weekdays: new Set(),
    selectedOption: 'never',
    ab: '',
    end: { type: 'date', value: '' },
    title: newEvent.title,
    description: newEvent.description,
    users: users,
    color: color,
    startTime: null,
    endTime: null,
    monthlyOption: 'sameDay', // new state for monthly options
    nthWeekday: 'first',
    weekday: 'Mo'
  });

  const [activeTab, setActiveTab] = useState(options[0]);

  const isWeekdaySelected = (weekday) => repeatData.weekdays.has(weekday);

  const toggleWeekdaySelection = (weekday) => {
    const updatedSelection = new Set(repeatData.weekdays);
    if (updatedSelection.has(weekday)) {
      updatedSelection.delete(weekday);
    } else {
      updatedSelection.add(weekday);
    }
    setRepeatData({
      ...repeatData,
      weekdays: updatedSelection,
    });
  };

  const sendRecurrenceToServer = async () => {
    try {
      const response = await  API.post ('/save-recurrence', {
        body: JSON.stringify({
          ...repeatData,
          weekdays: Array.from(repeatData.weekdays),
          end: {
            value: repeatData.end.value,
            type: repeatData.selectedOption === 'on' ? 'date' : 'number',
          },
          startTime: repeatData.startTime ? repeatData.startTime.toLocaleTimeString('en-GB') : null,
          endTime: repeatData.endTime ? repeatData.endTime.toLocaleTimeString('en-GB') : null,
        })
      });
  
      if (!response.ok) {
        throw new Error('Failed to save recurrence');
      }
  
      const responseData = await response.json();

      console.log(response);
      
      console.log('Antwort vom Server:', responseData.message);
  
      // Zeige eine Erfolgsmeldung an den Benutzer
      alert('Termin erfolgreich gespeichert!');
  
      // Rufe die Funktion zum Aktualisieren der Events auf
      await fetchEventsAndUpdate();
   
      setRepeatData({})
    } catch (error) {
      console.error('Fehler beim Senden an den Server:', error.message);
  //fetchEventsAndUpdate()
      // Optional: Zeige eine Fehlermeldung an den Benutzer
      alert('Fehler beim Speichern des Termins');
    }
  };

  return (
    <TabsContainer>
      <ButtonContainer>
        {options.map((option, index) => (
          <TabButton
            key={index}
            onClick={() => {
              setActiveTab(option);
              setRepeatData({
                ...repeatData,
                option: option,
              });
            }}
          >
            {option}
          </TabButton>
        ))}
      </ButtonContainer>
      {options.map((option) => (
        <TabContent key={option} isActive={activeTab === option}>
          <TabTitle>{option}</TabTitle>
          <TabText>
            {option === 'Wöchentlich' && (
              <div>
                Wiederholt an folgenden Tagen:
                <div>
                  {['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'].map((weekday) => (
                    <label key={weekday} style={{ marginRight: '10px' }}>
                      <input
                        type="checkbox"
                        checked={isWeekdaySelected(weekday)}
                        onChange={() => toggleWeekdaySelection(weekday)}
                      />
                      {weekday}
                    </label>
                  ))}
                  ab
                  <input
                    type="date"
                    placeholder="Datum"
                    value={repeatData.ab}
                    onChange={(e) => {
                      setRepeatData({
                        ...repeatData,
                        ab: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            )}
            {option === 'Monatlich' && (
              <div>
                Wiederholt monatlich:
                <div>
                  <label>
                    <input
                      type="radio"
                      name="monthlyOption"
                      value="sameDay"
                      checked={repeatData.monthlyOption === 'sameDay'}
                      onChange={(e) => setRepeatData({ ...repeatData, monthlyOption: e.target.value })}
                    />
                    Am gleichen Tag des Monats
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="monthlyOption"
                      value="nthWeekday"
                      checked={repeatData.monthlyOption === 'nthWeekday'}
                      onChange={(e) => setRepeatData({ ...repeatData, monthlyOption: e.target.value })}
                    />
                    Am
                  </label>
                  {repeatData.monthlyOption === 'nthWeekday' && (
                    <div>
                      <select
                        value={repeatData.nthWeekday}
                        onChange={(e) => setRepeatData({ ...repeatData, nthWeekday: e.target.value })}
                      >
                        <option value="first">Ersten</option>
                        <option value="second">Zweiten</option>
                        <option value="third">Dritten</option>
                        <option value="fourth">Vierten</option>
                        <option value="last">Letzten</option>
                      </select>
                      <select
                        value={repeatData.weekday}
                        onChange={(e) => setRepeatData({ ...repeatData, weekday: e.target.value })}
                      >
                        {['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'].map((day) => (
                          <option key={day} value={day}>{day}</option>
                        ))}
                      </select>
                    </div>
                  )}
                  ab
                  <input
                    type="date"
                    placeholder="Datum"
                    value={repeatData.ab}
                    onChange={(e) => {
                      setRepeatData({
                        ...repeatData,
                        ab: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            )}
            {option !== 'Wöchentlich' && option !== 'Monatlich' && (
              <div>
                Wiederholt {`${option} ab`}{' '}
                <input
                  type="date"
                  placeholder="Datum"
                  value={repeatData.ab}
                  onChange={(e) => {
                    setRepeatData({
                      ...repeatData,
                      ab: e.target.value,
                    });
                  }}
                />
              </div>
            )}
          </TabText>
          <TabText>
            Startzeit:
            <DatePicker
              selected={repeatData.startTime ? new Date(repeatData.startTime) : null}
              onChange={(time) => setRepeatData({ ...repeatData, startTime: time })}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Zeit"
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              placeholderText="Startzeit auswählen"
            />
          </TabText>
          <TabText>
            Endzeit:
            <DatePicker
              selected={repeatData.endTime ? new Date(repeatData.endTime) : null}
              onChange={(time) => setRepeatData({ ...repeatData, endTime: time })}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Zeit"
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              placeholderText="Endzeit auswählen"
            />
          </TabText>
          <TabText>
            Und endet
            <select
              onChange={(e) =>
                setRepeatData({
                  ...repeatData,
                  selectedOption: e.target.value,
                })
              }
              value={repeatData.selectedOption}
            >
              <option value="never">einmal</option>
              <option value="after">nach</option>
              <option value="on">am</option>
            </select>
            {repeatData.selectedOption === 'after' && (
              <span>
                <input
                  type="number"
                  placeholder="Anzahl"
                  onChange={(e) =>
                    setRepeatData({
                      ...repeatData,
                      end: { ...repeatData.end, value: e.target.value, type: 'number' },
                    })
                  }
                />
              </span>
            )}
            {repeatData.selectedOption === 'on' && (
              <input
                type="date"
                placeholder="Datum"
                value={repeatData.end.value}
                onChange={(e) => {
                  setRepeatData({
                    ...repeatData,
                    end: { ...repeatData.end, value: e.target.value, type: 'date' },
                  });
                }}
              />
            )}
          </TabText>
          <TabButton
            onClick={() => {
              sendRecurrenceToServer();
              setShowEventForm(false);
            }}
          >
            Speichern
          </TabButton>
        </TabContent>
      ))}
    </TabsContainer>
  );
};

export default RepeatKomponent;
