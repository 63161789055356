import React, { useState, useEffect, useContext } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  FormContainer,
  FormHeader1,
  InputEv,
  Button,
  SelectEmployee,
  Header,
  MitarbeiterPicker,
  DataContainerDatePicker,
  DataContainer,
  StyledDatePicker

} from './EventForm.element';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import format from 'date-fns/format';
import deLocale from 'date-fns/locale/de';
import API from '../context/axioSetup';
import { EmployeesContext, EventsContext } from '../context/EveMitContext';
import RepeatKomponent from './RepeatKomponent';


function EventEdit({ hoveredEvent, setShowEventEdit }) {
  const { employees } = useContext(EmployeesContext);
  const { fetchEventsAndUpdate } = useContext(EventsContext);
  const [isGanzerTag, setIsGanzerTag] = useState(false);
  const [showRepeatComponent, setShowRepeatComponent] = useState(false);
  const [eventData, setEventData] = useState({
    title: '',
    description: '',
    selectedEmployees: [],
    startTimeEvent: new Date(),
    endTimeEvent: new Date(),
    color: ''
  });
  const [notification, setNotification] = useState({ message: '', type: '' });

  useEffect(() => {
    if (hoveredEvent) {
      const { title, description, employee_names, start_datetime, end_datetime, color } = hoveredEvent;
      setEventData({
        title,
        description,
        color,
        selectedEmployees: employee_names || [],
        startTimeEvent: new Date(start_datetime),
        endTimeEvent: new Date(end_datetime),
      });
    }
  }, [hoveredEvent]);

  useEffect(() => {
    if (isGanzerTag) {
      const startOfDay = new Date(eventData.startTimeEvent || new Date());
      startOfDay.setHours(0, 0, 0, 0);
      const nextDay = new Date(startOfDay);
      nextDay.setDate(nextDay.getDate() + 1);
      nextDay.setHours(0, 0, 0, 0);

      setEventData({ ...eventData, startTimeEvent: startOfDay, endTimeEvent: nextDay });
    }
  }, [isGanzerTag]);

  const generateEventColor = (selectedEmployees) => {
    const colors = selectedEmployees.map(emp => emp.color); // Deine hex Farben wie #f16c20 etc.
    
    // Wenn nur ein Mitarbeiter beteiligt ist, wird dessen Farbe zurückgegeben
    if (colors.length === 1) {
      return colors[0];
    }
    
    // Wenn mehrere Mitarbeiter beteiligt sind, wird ein repeating-linear-gradient erstellt
    else if (colors.length > 1) {
      const gradientColors = colors.map((color, index) => {
        const position = index * 30; // z.B. 30px Schritt für jeden Farbblock
        return `${color} ${position}px, ${color} ${position + 30}px`;
      }).join(', ');
      
      return `repeating-linear-gradient(-45deg, ${gradientColors})`;
    } 
    
    // Wenn keine Mitarbeiter vorhanden sind, standardmäßig blau
    else {
      return 'blue';
    }
  };

  const removeSelectedEmployee = (employeeName) => {
    const updatedSelectedEmployees = eventData.selectedEmployees.filter((emp) => emp.name !== employeeName);
    setEventData({ ...eventData, selectedEmployees: updatedSelectedEmployees });
  };

  const addSelectedEmployees = (e) => {
    const newSelectedEmployees = Array.from(e.target.selectedOptions, (option) => {
      const employee = employees.find(emp => emp.name === option.value);
      if (eventData.selectedEmployees.find(selectedEmp => selectedEmp.name === employee.name)) {
        return null;
      }
      const color = employee ? (employee.farbe.startsWith('#') ? employee.farbe : `${employee.farbe}`) : '#default-color';
      return {
        name: option.value,
        color: color,
        id: employee.mitarbeiter_id
      };
    }).filter(emp => emp !== null);
    setEventData({ ...eventData, selectedEmployees: [...eventData.selectedEmployees, ...newSelectedEmployees] });
  };

  const handleDateChange = (date, field) => {
    setEventData({ ...eventData, [field]: date });
  };

  const handleEditButtonClick = async () => {
    const formattedStartDate = format(eventData.startTimeEvent, 'yyyy-MM-dd HH:mm:ss', { locale: deLocale });
    const formattedEndDate = format(eventData.endTimeEvent, 'yyyy-MM-dd HH:mm:ss', { locale: deLocale });

    const editedEventData = {
      event_id: hoveredEvent.event_id,
      start: formattedStartDate,
      end: formattedEndDate,
      title: eventData.title,
      description: eventData.description,
      selectedEmployees: eventData.selectedEmployees,
      color: generateEventColor(eventData.selectedEmployees),
    };

    try {
      const response = await API.post('/editEvent', editedEventData);

      if (response.status === 200) {
        setNotification({ message: response.data.message, type: 'success' });  // Erfolgsbenachrichtigung setzen
        fetchEventsAndUpdate();  // Event-Liste aktualisieren
      } else {
        setNotification({ message: 'Fehler beim Bearbeiten des Events', type: 'error' });  // Fehlerbenachrichtigung setzen
      }
    } catch (error) {
      setNotification({ message: 'Fehler beim Bearbeiten des Events: ' + error.message, type: 'error' });  // Fehlerbenachrichtigung setzen
    }

    setShowEventEdit(false);
  };

  const handleDeleteEvent = async () => {
    const eventID = hoveredEvent.event_id;

    const isConfirmed = window.confirm('Möchten Sie diesen Termin wirklich löschen?');
    if (isConfirmed) {
      try {
        const response = await API.delete(`/deleteEvent`, {
          data: { eventID: eventID }
        });

        if (response.status === 200) {
          setNotification({ message: response.data.message, type: 'success' });  // Erfolgsbenachrichtigung setzen
          fetchEventsAndUpdate();  // Event-Liste aktualisieren
          console.log(notification);
        } else {
          setNotification({ message: 'Fehler beim Löschen des Events', type: 'error' });  // Fehlerbenachrichtigung setzen
        }
      } catch (error) {
        setNotification({ message: 'Fehler beim Löschen des Events: ' + error.message, type: 'error' });  // Fehlerbenachrichtigung setzen
      }

      setShowEventEdit(false); // Schließt das Bearbeitungsfenster nach dem Löschen
    }
  };

  const handleRepeatCheckbox = (e) => {
    setShowRepeatComponent(e.target.checked);
  };

  const handleGanzerTagCheckbox = (e) => {
    setIsGanzerTag(e.target.checked);
  };

  return (
    <FormContainer showrepeatComponent={showRepeatComponent}>
      <FontAwesomeIcon icon={faTimes} onClick={() => setShowEventEdit(false)} style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }} />
      <Header>
        <FormHeader1>Bearbeite deinen Termin</FormHeader1>
      </Header>
      <InputEv type="text" placeholder="Titel" value={eventData.title} onChange={(e) => setEventData({ ...eventData, title: e.target.value })} />
      <InputEv
        type="text"
        placeholder="Description"
        value={eventData.description}
        onChange={(e) => setEventData({ ...eventData, description: e.target.value })}
      />
      <SelectEmployee multiple onChange={addSelectedEmployees}>
        {employees.map((employee) => (
          <option key={employee.ID} value={employee.name} style={{ backgroundColor: `${employee.farbe}` }}>
            {employee.name}
          </option>
        ))}
      </SelectEmployee>
      <MitarbeiterPicker>
        {eventData.selectedEmployees.map((employee) => (
          <span key={employee.name} style={{ display: 'flex', alignItems: 'center', backgroundColor: `${employee.color}` }}>
            {employee.name}
            <FontAwesomeIcon icon={faTimes} onClick={() => removeSelectedEmployee(employee.name)} />
          </span>
        ))}
      </MitarbeiterPicker>
      <DataContainerDatePicker  showrepeatComponent={showRepeatComponent}>
        <div>
          Von:
          <StyledDatePicker
            selected={eventData.startTimeEvent}
            onChange={(date) => handleDateChange(date, 'startTimeEvent')}
            showTimeSelect={!isGanzerTag}
            timeFormat="HH:mm"
            timeIntervals={30}
            timeCaption="Zeit"
            dateFormat="Pp"
            locale="de"
            inputMode="numeric"  // Verhindert, dass Telefonnummern interpretiert werden
            pattern="\d*"        // Akzeptiert nur numerische Eingaben
          
          />
        </div>
        <div>
          Bis:
          <StyledDatePicker
            selected={eventData.endTimeEvent}
            onChange={(date) => handleDateChange(date, 'endTimeEvent')}
            showTimeSelect={!isGanzerTag}
            timeFormat="HH:mm"
            timeIntervals={30}
            timeCaption="Zeit"
            dateFormat="Pp"
            locale="de"
            inputMode="numeric"  // Verhindert, dass Telefonnummern interpretiert werden
            pattern="\d*"        // Akzeptiert nur numerische Eingaben
          
          />
        </div>
      </DataContainerDatePicker>

      <DataContainer>
        <div>
          <input type="checkbox" id="ganzerTag" name="ganzerTag" checked={isGanzerTag} onChange={handleGanzerTagCheckbox} />
          <label htmlFor="ganzerTag">Ganzer Tag</label>
        </div>
        <div>
          <input type="checkbox" id="wiederholen" name="wiederholen" checked={showRepeatComponent} onChange={handleRepeatCheckbox} />
          <label htmlFor="wiederholen">Wiederholen</label>
        </div>
      </DataContainer>

      {showRepeatComponent && (
        <RepeatKomponent
          newEvent={eventData}
          users={eventData.selectedEmployees}
          color={generateEventColor(eventData.selectedEmployees)}
          setShowEventForm={setShowEventEdit}
        />
      )}

      <Button onClick={handleEditButtonClick} showRepeatComponent={showRepeatComponent}>Änderungen Speichern</Button>
      <Button onClick={handleDeleteEvent} showRepeatComponent={showRepeatComponent}>Den Termin Löschen</Button>
    </FormContainer>
  );
}

export default EventEdit;
